import axios, { AxiosRequestConfig } from 'axios';
import { isEmpty, isNil } from 'lodash';
import { FeatureDisplayDefaults, ProductDefaults } from '../containers/types';

const SERVER_BASE_PATH = process.env.REACT_APP_PRODUCT_SERVER;

export const PATHS = Object.freeze({
  GET_FEATURE_DISPLAY_DEFAULTS: () => `features/displayDefaults?active=true`,
  GET_USER_PRODUCT_DEFAULTS: (region: string) =>
    `products/get?region=${region}&active=true`,
  GET_COLOR_SCALE: `colorScale/get`,
  GET_RECOMMENDATIONS: `mapTile/get`,
  GENERATE_MAP_TILE: `mapTile/generate`,
  GET_CURSOR_DATA: `cursorData/all`,
  SAVE_PRODUCT_DEFAULTS: `products/saveDefaults`,
});

const getFeatureDisplayDefaults = async (
  userName: string,
  seastarId: string
): Promise<FeatureDisplayDefaults> => {
  try {
    const { data: displayDefaults } = await axios.get<FeatureDisplayDefaults>(
      SERVER_BASE_PATH + PATHS.GET_FEATURE_DISPLAY_DEFAULTS()
    );
    return displayDefaults;
  } catch (error) {
    throw error;
  }
};

const getUserProductDefaults = async (
  userName: string,
  region: string,
  seastarId: string
): Promise<ProductDefaults> => {
  try {
    const { data: ProductDefaults } = await axios.get<ProductDefaults>(
      SERVER_BASE_PATH + PATHS.GET_USER_PRODUCT_DEFAULTS(region)
    );
    return ProductDefaults;
  } catch (error) {
    throw error;
  }
};

const getColorScale = async (colorScale?: string) => {
  try {
    let config: AxiosRequestConfig = {};
    if (!isNil(colorScale) || !isEmpty(colorScale)) {
      config = {
        ...config,
        params: { color: colorScale },
      };
    }
    const { data: Colors } = await axios.get<any>(
      SERVER_BASE_PATH + PATHS.GET_COLOR_SCALE,
      config
    );
    return Colors;
  } catch (error) {
    throw error;
  }
};

const getRecommendations = async (
  userName: string,
  date: string,
  region: string,
  product: string,
  product_type: string,
  depth: number,
  defaultTile: boolean
) => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        date: date,
        region: region,
        product: product,
        product_type: product_type,
        depth: depth,
        default: defaultTile,
      },
    };
    const { data: MapTile } = await axios.get<any>(
      SERVER_BASE_PATH + PATHS.GET_RECOMMENDATIONS,
      config
    );
    return MapTile;
  } catch (error) {
    throw error;
  }
};

const generateMapTile = async (
  userName: string,
  date: string,
  region: string,
  product: string,
  product_type: string,
  depth: number,
  defaultTile: boolean,
  tileData?: any
) => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        date: date,
        region: region,
        product: product,
        product_type: product_type,
        depth: depth,
        default: defaultTile,
      },
    };
    const { data: mapTile } = await axios.post<any>(
      SERVER_BASE_PATH + PATHS.GENERATE_MAP_TILE,
      tileData ? tileData : {},
      config
    );
    return mapTile;
  } catch (error) {
    throw error;
  }
};

const getCursorData = async (
  userName: string,
  date: string,
  region: string,
  product: string,
  depth: number,
  format: string
) => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        date: date,
        regions: region,
        products: product,
        depth: depth,
        format: format,
      },
    };
    const { data: CursorData } = await axios.get<any>(
      SERVER_BASE_PATH + PATHS.GET_CURSOR_DATA,
      config
    );
    return CursorData;
  } catch (error) {
    throw error;
  }
};

const saveProductDefaults = async (
  userName: string,
  region: string,
  product_code: string,
  product_type: string,
  defaults: ProductDefaults
) => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {
        region: region,
        product_code: product_code,
        product_type: product_type,
      },
    };
    const { data: productDefaults } = await axios.post<ProductDefaults>(
      SERVER_BASE_PATH + PATHS.SAVE_PRODUCT_DEFAULTS,
      defaults,
      config
    );
    return productDefaults;
  } catch (error) {
    throw error;
  }
};

export interface ProductsAPIClient {
  readonly getFeatureDisplayDefaults: (
    userName: string,
    seastarId: string
  ) => Promise<FeatureDisplayDefaults>;
  readonly getUserProductDefaults: (
    userName: string,
    region: string,
    seastarId: string
  ) => Promise<ProductDefaults>;
  readonly getColorScale: (colorScale?: string) => Promise<any>;
  readonly getRecommendations: (
    userName: string,
    date: string,
    region: string,
    product: string,
    product_type: string,
    depth: number,
    defaultTile: boolean
  ) => Promise<any>;
  readonly getCursorData: (
    userName: string,
    date: string,
    region: string,
    product: string,
    depth: number,
    format: string
  ) => Promise<any>;
  readonly saveProductDefaults: (
    userName: string,
    region: string,
    product_code: string,
    product_type: string,
    defaults: ProductDefaults
  ) => Promise<ProductDefaults>;
  readonly generateMapTile: (
    userName: string,
    date: string,
    region: string,
    product_code: string,
    product_type: string,
    depth: number,
    defaultTile: boolean,
    tileData?: any
  ) => Promise<any>;
}

const ProductClient: ProductsAPIClient = Object.freeze({
  getFeatureDisplayDefaults,
  getUserProductDefaults,
  getColorScale,
  getRecommendations,
  getCursorData,
  saveProductDefaults,
  generateMapTile,
});

export default ProductClient;
