import axios, { AxiosRequestConfig } from 'axios';
import { TripData } from '../containers/types';

const SERVER_BASE_PATH = process.env.REACT_APP_PRODUCT_SERVER;

export const PATHS = Object.freeze({
  GET_TRIPS_DETAILS: `trip/getTrips`,
  SAVE_TRIP: `trip/save`,
  EXPORT_TRIP: `trip/export`,
  DELETE_TRIP: `trip/delete`,
});

const getTripDetails = async (userName: string): Promise<TripData[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: {},
    };
    const { data: tripDetails } = await axios.get<TripData[]>(
      SERVER_BASE_PATH + PATHS.GET_TRIPS_DETAILS,
      config
    );

    return tripDetails;
  } catch (error) {
    throw error;
  }
};

const saveTrip = async (
  userName: string,
  tripId: string,
  updatedTripData: TripData
) => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { trip_id: tripId },
    };
    const { data: tripData } = await axios.post<TripData>(
      SERVER_BASE_PATH + PATHS.SAVE_TRIP,
      updatedTripData,
      config
    );
    return tripData;
  } catch (error) {
    throw error;
  }
};

// exportTrip(userId: string, tripId: string): any {
//   return this.http.get(
//     `${environment.serviceHost}navigation/export_trip?user=${userId}&trip_id=${tripId}`,
//     { responseType: 'blob', observe: 'response' }
//   );
// }
const exportTrip = async (
  userName: string,
  tripId: string
): Promise<TripData[]> => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { trip_id: tripId },
      responseType: 'blob',
    };
    const { data: tripDetails } = await axios.get<TripData[]>(
      SERVER_BASE_PATH + PATHS.EXPORT_TRIP,
      config
    );

    return tripDetails;
  } catch (error) {
    throw error;
  }
};

const deleteTrip = async (userName: string, tripId: string) => {
  try {
    let config: AxiosRequestConfig = {};
    config = {
      ...config,
      params: { trip_id: tripId },
    };
    const status = await axios.delete<any, number[]>(
      SERVER_BASE_PATH + PATHS.DELETE_TRIP,
      config
    );
    return status;
  } catch (error) {
    throw error;
  }
};

export interface TripAPIClient {
  readonly getTripDetails: (userName: string) => Promise<TripData[]>;
  readonly saveTrip: (
    userName: string,
    tripId: string,
    updatedTripData: TripData
  ) => Promise<TripData>;
  readonly exportTrip: (userName: string, tripId: string) => Promise<any>;
  readonly deleteTrip: (userName: string, tripId: string) => Promise<number[]>;
}

const TripClient: TripAPIClient = Object.freeze({
  getTripDetails,
  saveTrip,
  exportTrip,
  deleteTrip,
});

export default TripClient;
